import L from 'leaflet';
import xStore from 'xStore';
import * as olc from './open-location-code.js'

// Default icons will not be loaded properly because of a bug in Leaflet.js, see https://github.com/Leaflet/Leaflet/issues/4968,
// probably these comments:
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-254068636 (problem description)
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-269750768 (workaround)
//
// Therefore the standard markers are re-imported from the Node package.

import iconRetinaUrl from '../node_modules/leaflet/dist/images/marker-icon-2x.png';
import iconUrl from '../node_modules/leaflet/dist/images/marker-icon.png';
import shadowUrl from '../node_modules/leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
});

var map = L.map('map');
var latLngPopup = L.popup();

var store = new xStore("map:", localStorage);

// Nützliche Funktionen
function latlngString(pos) {
    var s = "";
    s += Math.abs(pos.lat).toFixed(6);
    if (pos.lat >= 0) s += "N"; else s += "S";
    s += ", ";
    
    s += Math.abs(pos.lng).toFixed(6);
    if (pos.lng >= 0) s += "E"; else s += "W";
    
    return s;
}

function onMapDblClick(e) {
    latLngPopup
        .setLatLng(e.latlng)
        .setContent("<table><tr><td><b>Lat/Lng</b></td><td>" + latlngString(e.latlng) + "</td></tr><tr><td><b>OLC</b></td><td>"
            + olc.encode(e.latlng.lat, e.latlng.lng, 10) + "</td></tr></table>")
        .openOn(map);
}

var positionAttrib;
	
function onMouseMove(e) {
    if (positionAttrib !== undefined) {
        map.removeControl(positionAttrib);
    }
    positionAttrib = L.control.attribution({
        prefix: false,
        position: 'topright',
    });
    positionAttrib.addAttribution(latlngString(e.latlng)).addTo(map);
    positionAttrib.addAttribution(olc.encode(e.latlng.lat, e.latlng.lng, 10)).addTo(map);
}

function onMoveEnd() {
    // Aktuelle Position und Zoomstufe lokal speichern
    store.set('lastPosition', map.getCenter());
    store.set('lastZoom', map.getZoom());
}

L.tileLayer(
	// OpenStreetMap (Mapnik)
	//"http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", // domains: abc
	// OpenStreetMap (Mapnik-DE)
	"https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png",	// domains: abcd
	// MapQuest
	//"http://otile{s}.mqcdn.com/tiles/1.0.0/osm/{z}/{x}/{y}.png",  // domains: 1234
	{
		minZoom: 0, maxZoom: 18,
		subdomains: 'abcd',
	}).addTo(map);

L.control.scale({maxWidth: 200, imperial: false}).addTo(map);

// Attribution "Leaflet" löschen
map.attributionControl.setPrefix('');
// Eigene Attribution setzen
var attributionControl = L.control.attribution({prefix: false});
attributionControl
    .addAttribution("Kartenmaterial bereitgestellt von <a href='http://openstreetmap.de'>OpenStreetMap</a>")
    .addAttribution("hier noch <a href='http://www.openstreetmap.org/copyright'>Lizenzfoo</a>")
    .addTo(map);

map.on('dblclick', onMapDblClick);
map.on('mousemove', onMouseMove);
map.on('moveend', onMoveEnd);
map.doubleClickZoom.disable();

// Beim Start die letzte bekannte Position aus LocalStorage laden, falls vorhanden. Ansonsten zentrieren in Deutschland.
let lastPosition = store.get('lastPosition', [51.1, 9.5]);
let lastZoom = store.get('lastZoom', 7);
map.setView(lastPosition, lastZoom);
