export function encode(lat, lng, code_length) {
    const symbols = ['2', '3', '4', '5', '6', '7', '8', '9', 'C', 'F', 'G', 'H', 'J', 'M', 'P', 'Q', 'R', 'V', 'W', 'X']
    const resolution = [20, 1, 1/20, 1/400, 1/8000]
    const grid_size_degrees = resolution[-1]
    const grid_cols = 4
    const grid_rows = 5
    const code_length_normal = 10
    const code_length_extra = 11
    const seperator = '+'
    const separator_position = 8
    
    // Clipping and normalizing
    if (lat < -90) lat = -90
    if (lat > 90) lat = 90
    while (lng < -180) lng += 360
    while (lng > 180) lng -= 360

    // Calculation preparation
    lat += 90
    lng += 180

    code_length = Math.min(code_length_extra, Math.max(2, code_length))
    let code_pairs = Math.floor(code_length / 2)
    let code = ""

    // Calculate digit pairs
    for (var i of Array(code_pairs).keys()) {
        let divisor = resolution[i]
        
        let lat_idx = Math.floor(lat / divisor)
        let lng_idx = Math.floor(lng / divisor)
        code += symbols[lat_idx]
        code += symbols[lng_idx]

        lat = lat % divisor
        lng = lng % divisor
    }
    
    // Padding
    if (code_length < separator_position) {
        code = (code + "000000").slice(0, separator_position)

    }

    // Extra digit
    if (code_length == code_length_extra) {
        row = Math.floor(lat / grid_size_degrees * grid_rows)
        col = Math.floor(lng / grid_size_degrees * grid_cols)
        code += symbols[row * grid_cols + col]
    }

    return code.slice(0, separator_position) + seperator + code.slice(separator_position)
}
